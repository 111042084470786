/* Context Menu Styles */
.context-menu {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 1000;
    width: 150px;
    padding: 5px 0;
  }
  
  /* Menu Item Styling */
  .menu-item {
    display: block;
    width: 100%;
    padding: 8px 15px;
    text-align: left;
    border: none;
    background: white;
    cursor: pointer;
    font-size: 0.85rem;
    text-transform: capitalize;
    .menu-item__label, .menu-item__legend, .menu-item__checked {
        display: inline-block;
        vertical-align: middle;
    }
    .menu-item__checked {
      margin-left: 10px;
    }
    .menu-item__legend {
        width: 7px;
        height: 7px;
        border-radius: 50px;
        margin-right: 5px;
    }
  }
  
  /* Hover Effect */
  .menu-item:hover {
    background: #f5f5f5;
  }
  
  /* Ensure Table Doesn't Overflow */
  .table-container {
    padding: 20px;
  }
  