@import "src/styles/variables";
.auth-wrapper {
  button.ant-btn:not(.ant-btn-icon-only) {
    width: 100%;
    background-color: $primary-color;
    color: #FFFFFF;
    border-radius: 25px;
    padding: 10px;
  }
  .auth-wrapper__auth-section {
    background-color: #FFFFFF;
    color: $primary-black;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    height: 100vh;
    position: relative;
    > div {
      width: 60%;
      margin: 25% auto 0 auto;
    }
  }

  .auth-wrapper__logo-section {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    height: 100vh;
    background-color: $primary-color;
    color: #FFFFFF;
    font-size: 30px;
  }
  .auth-wrapper__logo-section-mobile {
    display: none; 
  }
  .auth-wrapper__logo-section-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 150px;
    margin: auto;
  }

  .auth-wrapper__logo, .auth-wrapper__logo-subtext {
    //width: 200px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
  }
  .auth-wrapper__logo-subtext {
    letter-spacing: 4px;
  }
  .auth-wrapper__powered-by-text {
    > span {
      &:first-child {
        font-size: 0.9rem;
      }
      &:last-child {
        margin-left: 5px;
      }
    }
    text-align: center;
    color: #FFFFFF;
    margin: 17rem 0 0;
    letter-spacing: 3px;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  @media (max-width: 768px) {
    .auth-wrapper__logo-section {
      display: none;
    }
    .auth-wrapper__auth-section {
      width: 100%;
      > div {
        width: 75%;
      }
    }
    .auth-wrapper__logo-section-mobile {
      text-align: center;
      display: block;
      h1, h5 {
        color: $primary-color;
      }
      h1 {
        font-size: 2.5rem;
        margin-bottom: 0;
        line-height: 2.5rem;
        letter-spacing: 4px;
      }
      h5 {
        margin-top: 0.5rem;
        font-size: 1rem;
        letter-spacing: 2px;
        margin-bottom: 2rem;
      }
    }
  }

}
