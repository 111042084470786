@import "src/styles/variables";

.app-header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 15%; /* Adjusted for desktop layout */
  width: 85%;
  padding: 0.5rem 1rem;
  height: 3.5rem;
  background-color: #FFFFFF;
  box-shadow: $primary-shadow;
  transition: left 0.3s ease, width 0.3s ease; // Smooth transition for left and width changes

  &.collapsed {
    left: 0; // Take full width when collapsed
    width: 100%; // Full width on collapse
  }

  &.expanded {
    left: 15%; // Adjusted for expanded layout
    width: 85%; // Width when expanded
  }

  .app-header__user-info {
    display: inline-block;
    margin-top: 0.3rem;
    margin-left: 1rem;
    text-transform: uppercase;

    .app-header__user-name {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}

/* Tablet devices (768px and below) */
@media (max-width: 768px) {
  .app-header {
    left: 0; /* Take up full width on tablet screens */
    width: 100%;
    height: 4rem; /* Slightly reduce height */
    padding: 0.8rem;

    &.collapsed {
      height: 3.5rem; /* Adjust height for collapsed state on tablet */
    }
  }

  .app-header__user-info {
    margin-left: 0.5rem; /* Reduce margin for tablet */
    
    .app-header__user-name {
      font-size: 0.9rem !important; /* Slightly reduce font size for tablet */
    }
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .app-header {
    left: 0; /* Full width on mobile screens */
    width: 100%;
    height: 3.5rem; /* Reduce height further for mobile */
    padding: 0.5rem;

    &.collapsed {
      height: 3rem; /* Adjust height for collapsed state on mobile */
    }
  }

  .app-header__user-info {
    margin-left: 0.2rem; /* Reduce margin for mobile */
    
    .app-header__user-name {
      font-size: 0.8rem !important; /* Further reduce font size for mobile */
    }
  }
}

.dicom-viewer-mode .app-header {
  display: none;
}

