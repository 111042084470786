@import "src/styles/variables";

.case-filter-form {
  position: relative;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  .input-field-wrapper, .dropdown-field, .date-picker-field {
    margin-bottom: 0 !important;
    input, .ant-select-selection-placeholder, input::placeholder {
      font-size: 0.8rem !important;
    }
  }
  .input-field__title, .dropdown-field__title, .date-picker-field__title {
    margin-bottom: 5px;
  }
  .input-field-wrapper .input-field.ant-input-affix-wrapper {
    padding: 8px 10px;
  }
  .dropdown-field .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 4px 10px;
  }
  .date-picker-field .ant-picker {
    padding: 8px 10px;
  }

  .case-filter-form__toggle-button {
    position: absolute;
    top: -41px;
    left: 65px;
  }
}