@import "src/styles/variables";

.login-form {
  .ui.form input,.ui.form input:focus {
    background-color: $primary-color;
    border-color: #FFFFFF;
    color: #FFFFFF;
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding-left: 0;
  }

  .ui.form {
    input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px transparent inset;
    }

    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px transparent inset;
    }
  }

  .ui.button {
    background-color: $primary-color;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-align: left;
    padding: 10px 15px;
  }

  .ui.button svg {
    float: right;
  }

  @media (max-width: 768px) {
    h1 {
      display: none;
    }
  }

}
