
@import "src/styles/variables";

.app-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15%; // Default width when not collapsed
  height: 100vh;
  background-color: $primary-color;
  color: #FFFFFF;
  padding: 3rem 0;
  transition: width 0.3s ease; // Smooth transition for collapsing

  &.collapsed {
    width: 60px; // Reduced width when collapsed
    padding: 1rem 0;
  }

  &:not(.collapsed) {
    min-width: 200px; // Set a minimum width only when NOT collapsed
  }

  h1 {
    color: #FFFFFF;
    text-align: center;
    font-size: 32px;
    transition: opacity 0.3s ease; // Smooth transition for text visibility
    opacity: 1;

    .collapsed & {
      opacity: 0; // Hide the logo text when collapsed
    }
  }

  .app-sidebar__toggle {
    position: absolute;
    top: 1.7rem; // Adjust the top position as needed
    left: 50%; // Center horizontally
    transform: translateX(-50%); // Centering transformation
    z-index: 1; // Ensure it's above other elements
    transition: left 0.3s ease, transform 0.3s ease;
    font-size: 20px;

    &.collapsed {
      left: 50%; // Keep it centered when collapsed
      transform: translateX(-50%); // Centering transformation
      top: 6rem; 
    }

    // Position to the right when expanded
    &.expanded {
      left: auto; // Reset left positioning
      right: 0.5rem; // Position it at the right corner
      transform: translateX(0); // Reset transformation
    }
  }

  @media (max-width: 1340px) {
    .app-sidebar__toggle {
        display: none;
    }
  }

  .app-sidebar__role {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    transition: opacity 0.3s ease;
    opacity: 1;

    .collapsed & {
      opacity: 0; // Hide the role text when collapsed
    }
  }

  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    background-color: transparent;
    color: #FFFFFF;
    font-size: 1rem;
    border: none;
    margin-top: 1rem;

    .ant-menu-item {
      padding: 0.5rem 2rem !important;
      height: auto;
      border-left: 4px solid transparent;
      margin-bottom: 0;
      display: flex; // Align icon and text in a single row
      align-items: center; // Vertically center the icon and text
      transition: padding 0.3s ease, background-color 0.3s ease; // Adjust padding when collapsed

      .ant-menu-item-icon {
        margin-right: 10px; // Space between icon and text
        color: #FFFFFF;
        background-color: transparent; // Ensure no background for the icon
        transition: background-color 0.3s ease;
      }

      &:hover {
        background-color: $light-white;
        color: #FFFFFF;
        border-left: 4px solid #FFFFFF;

        .ant-menu-item-icon {
          background-color: transparent; // Ensure the icon has no background
        }
      }

      &.ant-menu-item-selected {
        background-color: $light-white;
        color: #FFFFFF;
        font-weight: bold;
        border-left: 4px solid #FFFFFF;

        .ant-menu-item-icon {
          background-color: transparent; // Keep the icon background transparent when selected
        }

        &:after {
          border-right: none;
        }
      }

      .collapsed & {
        padding: 0.5rem 1rem !important; // Adjust padding for collapsed state
      }
    }
  }

  .ant-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical{
    margin-top: 7rem;
    background-color: $primary-color;
    color: #FFFFFF;
    width: 60px;
    border: none;
  }

  /* Mobile devices (480px and below) */
  @media (max-width: 768px) {
    &.collapsed {
      width: 40px;
    }
    .ant-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical{
      margin-top: 7rem;
      background-color: $primary-color;
      color: #FFFFFF;
      width: 40px;
    }
  }

  .app-sidebar__logo {
    width: 80%;
    display: block;
    margin: 0 auto 1rem auto;
    transition: opacity 0.3s ease, width 0.3s ease;
    opacity: 1;

    .collapsed & {
      width: 40px; // Reduce logo size when collapsed
      opacity: 0; // Hide logo when collapsed
    }
  }

  .app-sidebar__logo-subtext {
    color: #FFFFFF;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 3px;
    margin-bottom: 2rem;
    transition: opacity 0.3s ease;
    opacity: 1;

    .collapsed & {
      opacity: 0; // Hide subtext when collapsed
    }
  }

  .app-sidebar__footer {
    position: absolute;
    width: 100%;
    bottom: 2rem;

    h1 {
      font-size: 1.5rem;
      span {
        font-size: 1rem;
      }
      transition: opacity 0.3s ease;
      opacity: 1;

      .collapsed & {
        opacity: 0; // Hide footer text when collapsed
      }
    }
  }
}

.dicom-viewer-mode .app-sidebar {
  display: none;
}

.anticon.ant-menu-item-icon svg{
  font-size: 20px;
}


