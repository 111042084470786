@import "src/styles/variables";


.admin-cases__tags {
  display: block;
  .admin-cases__tag-item {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 5px;
  }
}

.resizable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: initial;
}

.resizer::after {
  width: 3px;
  cursor: col-resize;
  background-color: transparent;
  height: 58%;
  position: absolute;
  right: 0px;
  top: 0;
  background-color: #c3c3c3;
  content: '';
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.admin-cases {
  .ant-table-column-sorters {
    position: initial;
  }
  .ant-table-column-sorter {
    margin-left: 0px;
    position: absolute;
    right: 20px;
  }
}

.admin-cases__status-wrapper {
  width: 380px;
  span, button {
    display: inline-block;
    vertical-align: middle;
  }
}

.admin-cases__patient-name, .admin-cases__referring-doctor, .admin-cases__assigned-doctor {
  word-break: break-word;
}

.admin-cases__study {
  max-width: 150px;
}

.admin-cases__date {
  padding-right: 30px;
}
.admin-cases__status {
  position: relative;
  text-transform: capitalize;
  font-weight: bold;
  color: $success-color;
  width: 100px;

  &.assigned {
    color: $warning-color;
  }

  &.unassigned {
    color: $danger-color;
  }
}

.ant-popover.admin-cases__assignment-popover {
  width: 300px;
}

.ant-popover.admin-cases__share-popover {
  .ant-popover-inner-content {
    padding: 12px 0;
  }
}

.clickable-icon {
  cursor: pointer;
  transition: transform 0.1s ease;
}

.clickable-icon:active {
  transform: scale(0.9);
}

@keyframes blink {
0% { opacity: 1; }
50% { opacity: 0; }
100% { opacity: 1; }
}