@import "src/styles/variables";

.doctor-cases__tags {
  display: block;
  .doctor-cases__tag-item {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 5px;
  }
}

.doctor-cases__patient-name {
  word-break: break-word;
}

.doctor-cases__status-wrapper {
  width: 350px;
  span, button {
    display: inline-block;
    vertical-align: middle;
  }

  .ant-btn.ant-btn-primary:not(.ant-btn-icon-only) {
    background-color: #1747E9;
    border: 1px solid #1747E9;
    padding: 4px 10px;
    font-size: 14px;
  }

  .ant-btn.ant-btn-primary {
    padding: 4px 15px;
  }
}

.doctor-cases__status {
  position: relative;
  text-transform: capitalize;
  font-weight: bold;
  color: $success-color;
  width: 100px;

  &.assigned {
    color: $warning-color;
  }

  &.unassigned {
    color: $danger-color;
  }
}

.doctor-cases__referring-doctor {
  word-break: break-word;
}

.clickable-icon {
  cursor: pointer;
  transition: transform 0.1s ease;
}

.clickable-icon:active {
  transform: scale(0.9);
}

.resizable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: initial;
}

.resizer::after {
  width: 2px;
  cursor: col-resize;
  background-color: transparent;
  height: 58%;
  position: absolute;
  right: 0px;
  top: 0;
  background-color: #d2d2d2;
  content: '';
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.doctor-cases {
  .ant-table-column-sorters {
    position: initial;
  }
  .ant-table-column-sorter {
    margin-left: 0px;
    position: absolute;
    right: 20px;
  }
}
