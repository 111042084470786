@import "src/styles/variables";



.case-sharing {
    padding: 0 1rem;
    .case-sharing__label {
        margin-bottom: 0.5rem;
        .anticon {
          margin-right: 0.5rem;
        }
    }
    .case-sharing__value {
        > div {
            margin-bottom: 0.5rem;
        }
      input, button {
        display: inline-block;
      }
      button {
        margin-left: 0.5rem;
      }
      input, div.ant-select {
        width: 80%;
      }
    }
}