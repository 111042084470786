
.tag-legend {
    margin-top: -2rem;
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
    .tag-legend__legend-item {
        margin-right: 1rem;
        .tag-legend__color, .tag-legend__name {
            display: inline-block;
            vertical-align: middle;
        }
        .tag-legend__color {
            width: 8px;
            height: 8px;
            border-radius: 50px;
            margin-right: 0.5rem;
        }
    }
}