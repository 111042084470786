@import "src/styles/variables";

.scan-center-cases {

}

.scan-center-cases__status-loader {
  .ant-spin.ant-spin-lg.ant-spin-spinning.app-loader {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 1rem;
    .ant-spin-dot {
     font-size: 1.5rem;
    }
  }
}
.clickable-icon {
  cursor: pointer;
  transition: transform 0.1s ease;
}

.clickable-icon:active {
  transform: scale(0.9);
}

.resizable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: initial;
}

.resizer::after {
  width: 2px;
  cursor: col-resize;
  background-color: transparent;
  height: 58%;
  position: absolute;
  right: 0px;
  top: 0;
  background-color: #d2d2d2;
  content: '';
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.scan-center-cases__tags {
  display: block;
  .scan-center-cases__tag-item {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-right: 5px;
  }
}

.scan-center-cases {
  .ant-table-column-sorters {
    position: initial;
  }
  .ant-table-column-sorter {
    margin-left: 0px;
    position: absolute;
    right: 20px;
  }
}

.scan-center-cases__status {
  position: relative;
  text-transform: capitalize;
  font-weight: bold;
  color: $success-color;
  width: 100px;

  &.report_pending {
    color: $warning-color;
  }

  &.unassigned {
    color: $danger-color;
  }
}

.scan-center-cases__status-wrapper {
  width: 350px;
  span, button {
    display: inline-block;
    vertical-align: middle;
  }
}