@import "variables";
@import "helpers";
@import "antOverrides";

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
  height: unset;
}

body, h1, h2, h3, h4, h5, button, input {
  font-family: 'Lato', sans-serif !important;
}

.app-wrapper {
  transition: margin 0.3s ease; // Smooth transition for margin changes

  // Default styles for expanded state
  margin: 4rem 3% 0 18%; // Adjust these values as needed for larger screens

  &.collapsed {
    margin-left: 5%;
    margin-right: 1.5%;
  }

  &.expanded {
    margin-left: 18%; // Margin when sidebar is expanded
  }

  @media (max-width: 1300px) {
    margin: 4rem 2% 0 2%; // Adjust margins for tablet view

    &.collapsed {
      margin-left: 6%; // Adjust this value for tablet when collapsed
      margin-right: 2%; // Adjust this value for tablet when collapsed
    }

    &.expanded {
      margin-left:15%; // Adjust this value for tablet when expanded
    }
  }
  // Tablet devices (768px and below)
  @media (max-width: 768px) {
    margin: 4rem 2% 0 2%; // Adjust margins for tablet view

    &.collapsed {
      margin-left: 8%; // Adjust this value for tablet when collapsed
      margin-right: 2%; // Adjust this value for tablet when collapsed
    }

    &.expanded {
      margin-left:20%; // Adjust this value for tablet when expanded
    }
  }

  // Mobile devices (480px and below)
  @media (max-width: 480px) {
    margin: 3rem 1% 0 1%; // Adjust margins for mobile view

    &.collapsed {
      margin-top: 4rem;
      margin-left: 15%; // Adjust this value for mobile when collapsed
      margin-right: 4%; // Adjust this value for mobile when collapsed
    }

    &.expanded {
      margin-left: 8%; // Adjust this value for mobile when expanded
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

/* Outline */
*:focus {
  outline: none !important;
}
