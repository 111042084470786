@import "src/styles/variables";

/* Button styles */

button.ant-btn {
  &:not(.ant-btn-icon-only) {
    padding: 5px 20px;
    height: auto;
    border-radius: 4px;
    font-size: 1rem;
  }
  &.ant-btn-icon-only {
   background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #FFFFFF;
    border-radius: 4px;
  }
}

.ant-btn.ant-btn-link {
  color: $primary-color;
}

.ant-btn.ant-btn-primary:not(.ant-btn-icon-only) {
  background-color: $primary-color;
  border: 1px solid $primary-color;
  padding: 5px 18px;
  font-size: 16px;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  background-color: $primary-color-light;
  border: 1px solid transparent;
  color: #FFFFFF;
}


/* Table styles */
.ant-table-wrapper {
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
  }
}
.ant-table {
  font-size: 0.85rem;
  margin: 1rem 0;
  .ant-table-column-sorters {
    padding: 10px 16px;
  }
  .ant-btn-icon-only {
    width: 27px;
    height: 30px;
  }
  .ant-btn-icon-only > * {
    font-size: 13px;
  }
}

.ant-table-tbody > tr:nth-child(even) {
  background-color: #f5f5f5 !important;
  td {
    background-color: #f5f5f5 !important;
  }
}

.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 3px 16px;
}
.ant-table-thead > tr > th {
  padding: 10px 16px;
}

/* Modal styles */

.ant-modal.primary-modal {
  width: 60% !important;

  .ant-modal-body {
    padding: 2.5rem 3.5rem;
    font-size: 1rem;
  }

  .ant-modal-content {
    border-radius: 10px;
  }
}

/* Tab styles */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover {
  color: $primary-color;
}

.ant-tabs-ink-bar {
  background: $primary-color;
}

/* Table styles */

.ant-table {
  .ant-table-container table > thead > tr > th {
    font-weight: bold;
    vertical-align: top;
  }
}



@media (max-width: 768px) {
  .ant-pagination-options {
    display: inline-block;
  }
  .ant-table-pagination {
    display: block;
    &.ant-table-pagination-right {
      justify-content: flex-start;
    }
    li {
      margin-bottom: 0.5rem;
    }
  }
}